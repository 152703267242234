import { useState } from 'react'
import {Row, Col} from 'react-bootstrap'
import  {getSkills, getOtherSkills, endPoint} from "./fetch"
import "../styles/skills.scss"

export default function Skills() {
    const [skills, setSkills] = useState([])
    const [otherSkills, setOtherSkills] = useState([])
    if (!skills.length) {
        getSkills(setSkills)
    }
    if (!otherSkills.length) {
        getOtherSkills(setOtherSkills)
    }
    return (
        <section id={"Skills"}>
            <Row  >
                <Col>
                    <Row className="skills-header">
                        <h3 className="pale-text"><b>{"Programming Languages, Skills, Frameworks"}</b></h3>
                    </Row>
                    <Row className="skill-icons" style={{height: "auto"}} lg={6} md={5} sm={3}>
                        {skills.map(s => {
                            return (
                                    <Col className="imgHolder" key={s.name}>
                                        <img src={`${endPoint}${s.icon}`} alt="python-icon" style={{maxHeight: "70px", maxWidth: "70px"}}/><br/ ><br/ >
                                        <b className="pale-text">{s.name}</b>
                                    </Col>
                            )
                        })}
                    </Row>
                    <Row className="lower-line skills-header" style={{paddingTop: "60px", height: "auto"}}>
                        <h3 className="pale-text"><b>Other Key Skills</b></h3>
                    </Row>
                    <Row className="skills-header" lg={1}>
                            {
                                otherSkills.map(o => <p className="pale-text" style={{fontSize: "large", lineHeight: "30px"}} key={o.memo}>{`• ${o.memo}`}</p>)
                            }
                    </Row>
                </Col>
            </Row>
        </section>
    )
}