import {Card, Button, Modal, Carousel, Container, Row} from 'react-bootstrap'
import {useState} from 'react'
import  {getExperiences, getEducations, endPoint} from "./fetch"
import "../styles/eduexp.scss"


const ExperienceInfo = {
    heading: "All Experiences",
    fields: ["Role", "Description"],
    // caller: getExperiences
}

const EducationInfo = {
    heading: "Education History",
    fields: ["Degree", "GPA"],
    // caller: getEducations
}

function MyModal(props) {
    let k = 0
    // const [data, setData] = useState([])
    // props.info.caller(setData)
    return (
      <>
        <Modal
        show={props.show}
        dialogClassName="modal-90"
        centered
      >
          <Modal.Header onClick={props.handleClose} closeButton>
            <Modal.Title className="text-center">{props.info.heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body id="ModalBody">
            {/* {
            data
                ? */}
            <Container>
                <Carousel id="MyCarousel" variant="light">
                    {props.data.map(i => {
                        k += 1
                        return (
                            <Carousel.Item key={k}>
                                <Row className={"justify-content-center"}>
                                    <Card id="education" className={"width-60"}>
                                        <b className="card-header">
                                            <img src={`${endPoint}${i.logo}`} alt={"Logo"} style={{height: "45px", width: "45px"}}/>
                                            &emsp;{i.name}
                                        </b>
                                        <Card.Body>
                                            {props.info.fields.map(f => {
                                                k += 1
                                                let f_l = f.toLowerCase()
                                            return  <p key={k}><b>{f}</b>: {i[f_l]}</p>
                                            })}
                                        </Card.Body>
                                        <Card.Footer>
                                            <Row className="justify-content-between">
                                                <p>Start: {i.start_date}</p>
                                                <p>End: {i.end_date}</p>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Row>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Container>
                {/* :
            null
            } */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  

export default function EduExp() {
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(null)
    const [data, setData] = useState([])
    const handleClose = () => {setShow(false);}
    const handleShow = (e) => {
        setShow(true);
        if (e.target.id === "ExpBtn") {
            setInfo(ExperienceInfo)
            getExperiences(setData)
        } else {
            setInfo(EducationInfo)
            getEducations(setData)
        }
    }

    return (
        <div>
            {
            info
                ?
            <MyModal show={show} handleClose={handleClose} handleShow={handleShow} info={info} data={data}/>
                :
            null
            }
            <div className="row">
                <Card id="Education" className="col-md-6">
                    <h5 className="card-header">Education</h5>
                    <Card.Body>
                        <Card.Title>Current: Dartmouth College</Card.Title>
                        <p>Level: Bachelor of Science</p>
                        <p className="card-text">G.P.A: 3.09/4.00</p>
                        <Button onClick={handleShow} id="EduBtn">See All</Button>
                    </Card.Body>
                </Card>
                <Card id="Experience" className="col-md-6">
                    <h5 className="card-header">Experience</h5>
                    <Card.Body>
                        <Card.Title>Most Recent: Uber</Card.Title>
                        <p className="card-text tab">From: July 2021 - Sept 2021</p>
                        <p className="card-text tab">Relevvant: SWE Summer Intern</p>
                        <Button onClick={handleShow} id="ExpBtn">See All</Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}
