import MyNav from './components/nav';
import MyFooter from './components/footer';
import Home from './components/home';
import ScrollToTop from './components/scrolltop';
import * as $ from 'jquery'
import React, {useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss'

function App() {
  const [sh, setSh] = useState(window.scrollY);
  const top = $("#Top")
  $(window).on("scroll", (e) => {
    setSh(window.scrollY)
  })

  return (
      <div className="App" id={"App"}>
        {
          sh < top.height()*1/2 || !top[0]
          ?
          <MyNav fixed="" theme="light" bg="#FFFFFF" link_color="#472776" transition={false}/>
          :
          <MyNav fixed="top" theme="dark" bg="#0e0d55" link_color="#FFFFFF" transition={true}/>
        }
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
          </Switch>
        </Router>
        <MyFooter/>
      </div>
  );
}

export default App;
