import {Nav, Navbar} from 'react-bootstrap'
import * as $ from 'jquery'
import '../styles/nav.scss'
import Logo from "../images/logo.png"

export default function MyNav(props) {
    function singlePageNavHandler(e) {
        e.preventDefault()
        const target = $(e.target.href.split("/")[3])
        const top = target[0].offsetTop - 81
        $("html, body").animate({
            scrollTop: top
        }, 1000)
    }

    if (props.transition) {
        const navBar = $("#Nav")
        navBar.css("transform", "translateY(-100%)")
        navBar.css("transform", "translateY(0%)")
        navBar.css("transition", "all 1.5s ease-in-out")
    }
    const mouseEnter = (e) => {
        $(`#${e.target.id}`).css({color: "#bd3725", textShadow: "2px 2px 3px red"})
    }
    const mouseLeave = (e) => {
        $(`#${e.target.id}`).css({color: props.link_color, textShadow: "0px 0px 0px white"})
    }
    return (
        <Navbar fixed={props.fixed} id="Nav" collapseOnSelect expand="lg" className="bg-switch-scroll" variant={props.theme} style={{backgroundColor: props.bg}}>
            <Navbar.Brand href="#Nav" className="logo">
                <img id="logo" src={Logo} alt="K.C"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav.Item>
                    <Nav.Link id={"Home_Link"} onClick={singlePageNavHandler} href="#Nav" className="no-style-link" style={{color: props.link_color}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link id={"About_Link"} onClick={singlePageNavHandler} href="#About" eventKey="about" className="no-style-link" style={{color: props.link_color}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>About</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link id={"Education_Link"} onClick={singlePageNavHandler} href="#Education" eventKey="education" className="no-style-link" style={{color: props.link_color}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>Education</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link id={"Experience_Link"} onClick={singlePageNavHandler} href="#Experience" eventKey="experience" className="no-style-link" style={{color: props.link_color}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>Experience</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link id={"Skills_Link"} onClick={singlePageNavHandler} href="#Skills" eventKey="skills" className="no-style-link" style={{color: props.link_color}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>Skills</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link id={"Projects_Link"} onClick={singlePageNavHandler} href="#Projects" eventKey="projects" className="no-style-link" style={{color: props.link_color}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>Projects</Nav.Link>
                </Nav.Item>
            </Navbar.Collapse>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav.Item>
                        <Nav.Link id={"Contact_Link"} onClick={singlePageNavHandler} href="#Contact" eventKey="contact" className="no-style-link" style={{color: props.link_color, textDecoration: "underline"}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>Get In Touch</Nav.Link>
                </Nav.Item>
            </Navbar.Collapse>
        </Navbar>
    )
    
}