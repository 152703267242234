import { Carousel, Card, Container, Row, Button} from "react-bootstrap";
import {getProjects, endPoint} from "./fetch"
import { useState} from "react";

export default function Project(props) {
    const [data, setData] = useState([])
    if (!data.length) {
        getProjects(setData)
    }
    return (
        <Container id={"Projects"}>
            <Carousel id="MyCarousel" variant="light">
                {
                    data.map(d => {
                        return (
                             <Carousel.Item key={d.name}>
                                <Row className={"justify-content-center"}>
                                    <Card id="education" className={"width-60"}>
                                        <b className="card-header">
                                            {d.name}
                                        </b>
                                        <Card.Img variant="top" src={`${endPoint}${d.image}`} alt="Project Logo" style={{height: "300px"}}/>
                                        <Card.Body>
                                            {d.memo}
                                            {
                                            d.link.length 
                                                ?
                                            <p className={"text-center"}><br/ ><Button target="_blank" rel="no-ref" href={d.link} style={{position: "relative", zIndex: "2"}}>Visit</Button></p>
                                                :
                                            null
                                        }
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </Container>
    )
}