import {Row, Col} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { getAbout, endPoint } from './fetch'
import showdown from 'showdown'
import * as $ from 'jquery'

export default function About() {
    const [data, setData] = useState([])
    const converter = new showdown.Converter()
    useEffect(() => {
        if(data.length) {
            const text_p = $("#AboutText")
            if (text_p[0]) {
                text_p[0].innerHTML = converter.makeHtml(data[0].about)
            }
        }
    })

    if (!data.length) {
        getAbout(setData)
    }
    return (
        <div id={"About"}>
            {
            data.length
                ?
            <div id="about" className="col">
                <Row>
                    <Col id="MyImageHolder"  className="imgHolder">
                        <img id="myImg" src={`${endPoint}${data[0].image}`} alt="Me"/>
                    </Col>
                    <Col>
                    <h1 className="pale-text" style={{textAlign: "center"}}>About Me</h1>
                        <p className="pale-text" id={"AboutText"}/>
                    </Col>
                </ Row>
            </div>
                :
            null
            }
        </div>
    )
}