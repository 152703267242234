import { Button, Row, Col} from "react-bootstrap";
import { useEffect, useState } from "react";
import { sendMessage } from "./fetch";

export default function MyFooter() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [errors, setErrors] = useState({name: [""], email: [""], phone: [""], message: [""]})
    const [sent, setSent] = useState()
    const submit = () => {
        sendMessage({
            name: name,
            email: email,
            phone: phone,
            message: message
        }, setErrors, setSent, erase)
    }
    const erase = () => {
        setName("")
        setEmail("")
        setPhone("")
        setMessage("")
        setErrors({name: [""], email: [""], phone: [""], message: [""]})
    }

    useEffect(() => {
        console.log(errors)
    }, [errors])

    return (
        <div id={"Contact"} style={{backgroundColor: "#0e0d55", color: "#FFFFFF", padding: "20px"}}>
            <Row id="contacts" lg={2} md={2} sm={1} xs={1}>
                <Col>
                    <form>
                        <h2>Get in Touch</h2>&emsp;{sent}
                        <div className="form-group">
                            <div className="form-row">
                                {<p style={{color: "red"}}>{errors.name[0]}</p>}
                                <input type="text" className="form-control" name="name" placeholder="Your Name" aria-label="Your Name" value={name} onChange={(e) => {setName(e.target.value)}} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-row">
                                {<p style={{color: "red"}}>{errors.email[0]}</p>}
                                <input type="email" className="form-control" name="email" placeholder="Your Email" aria-label="Your Email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-row">
                                {<p style={{color: "red"}}>{errors.phone[0]}</p>}
                                <input type="phone" className="form-control" name="phone" placeholder="Your Phone" aria-label="Your Phone" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-row">
                                {<p style={{color: "red"}}>{errors.message[0]}</p>}
                                <textarea placeholder="Write a Message..." className="form-control" name="message" aria-label="Message Body" rows="10" value={message} onChange={(e) => {setMessage(e.target.value)}}/>
                            </div>
                        </div>
                        <Button className="btn-dark roundBtn" onClick={submit}>
                            Send Message
                        </Button>
                    </form>
                </Col>
                <Col>
                    <h2>My Contact Detail</h2>
                    <p>
                        <span className="contact-label">Email</span><br />
                        <span className="contact-label">
                            okorie.23@dartmouth.edu
                        </span>
                    </p>
                    <p>
                        <span className="contact-label">Phone</span><br />
                        <span className="contact-label">
                            +1 (713)-960-2671
                        </span>
                    </p>
                    <p>
                        <span className="contact-label">Address</span><br />
                        <span className="contact-label">
                            3550 Hinman Box,<br />
                            Dartmouth College,<br />
                            Hanover New Hampshire
                        </span>
                    </p>
                    <div>
                        {/* <span className="facebook"><a href="#" className="fa fa-facebook" id="facebooklink">F</a></span>
                        <span className="linkedIn"><a href="#" className="fa fa-linkedin" id="linkedinLink">L</a></span>
                        <span className="instagram"><a href="#" className="fa fa-instagram" id="instaLink">I</a></span> */}
                    </div>
                </Col>
            </Row>
        </div>
    )
}