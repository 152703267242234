// export const endPoint = "http://localhost:8000"
export const endPoint = "https://rehoboth.link"

export function getAbout(setter) {
    fetch(endPoint + "/about/")
    .then(res => res.json())
    .then(data => {
        setter(data)
    })
    .catch(err => {
        recall(getAbout, setter)
    })
}

export function getExperiences(setter) {
    fetch(endPoint + "/experience/")
    .then(res => res.json())
    .then(data => {
        setter(data)
    })
    .catch(err => {
        recall(getExperiences, setter)
    })
}

export function getEducations(setter) {
    fetch(endPoint + "/education/")
    .then(res => res.json())
    .then(data => {
        setter(data)
    })
    .catch(err => {
        recall(getEducations, setter)
    })
}

export function getSkills(setter) {
    fetch(endPoint + "/skills/")
    .then(res => res.json())
    .then(data => {
        setter(data)
    })
    .catch(err => {
        recall(getSkills, setter)
    })
}

export function getProjects(setter) {
    fetch(endPoint + "/project/")
    .then(res => res.json())
    .then(data => {
        setter(data)
    })
    .catch(err => {
        recall(getSkills, setter)
    })
}

export function getOtherSkills(setter) {
    fetch(endPoint + "/otherskills/")
    .then(res => res.json())
    .then(data => {
        setter(data)
    })
    .catch(err => {
        recall(getOtherSkills, setter)
    })
}

export function sendMessage(body, setter, setSent, erase) {
    fetch(endPoint + "/message/", {
        method: "post",
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
          },
        body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(data => {
        if(data.errors) {
            data.errors.name = data.errors.name || [""]
            data.errors.email = data.errors.email || [""]
            data.errors.phone = data.errors.phone || [""]
            data.errors.message = data.errors.message || [""]
            setter(data.errors)
        } else {
            setSent(<h6 style={{color: "green"}}>Message Sent&emsp;&#10003;</h6>)
            setTimeout(() => {
                setSent("")
            }, 5000)
            erase()
        }
    })
    .catch(err => {
    })
}

function recall(func, param) {
    setTimeout(() => {
        func(param)
    }, 2000);
}

function getCookie(name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";")
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim()
            if (cookie.substring(0, name.length + 1) == (name + "=")) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}

