export default function Top() {
    return (
        <div id="Top">
            <div id="Home" className="text-center">
                <a href="https://www.linkedin.com/in/rehoboth-okorie" className="linkedIn" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                <a href="https://github.com/rehoboth23" className="github" target="_blank" rel="noreferrer"><i className="fa fa-github-square"></i></a>
                <h1 className="my-auto intro-text"><b>Hi!</b></h1>
                <h4 className="my-auto intro-text"><b>I'm Rehoboth Okorie</b></h4>
                <p className="my-auto intro-text">Welcome to my Portfolio!</p>
            </div>
        </div>
    )
}