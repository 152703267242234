import Top from './top'
import About from './about'
import EduExp from './eduexp'
import Skills from './skills'
import Project from './projects_home'
import '../styles/home.scss'

export default function Home() {
    return (
        <div style={{position: "relative", width: "100%"}} >
            <Top/><hr />
            <About/><hr />
            <EduExp/><hr />
            <Skills/><hr />
            <Project/><hr />
        </div>
    )
}